<template>
    <div class="pre-loader">
      <div class="spinner"></div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'PreLoader',
  };
  </script>
  
  <style scoped>
  .pre-loader {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: white; /* Fondo blanco sin transparencia */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
  }
  
  .spinner {
    border: 4px solid rgba(255, 255, 255, 0.3);
    border-top: 4px solid #3498db;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin 1s linear infinite;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  </style>
  