<template>
  <div id="app">
    <!-- PreLoader que solo se muestra cuando isLoading es verdadero -->
    <PreLoader v-if="isLoading" class="pre-loader" />
    
    <!-- El contenido existente (Navbar y router-view) solo se muestra cuando isLoading es falso -->
    <Navbar @start-loading="handleStartLoading" />
    <br />
    <router-view />
  </div>
</template>

<script>
import Navbar from './components/Navbar.vue';
import PreLoader from './components/Pre-loader.vue';

export default {
  name: 'App',
  components: {
    Navbar,
    PreLoader,
  },
  data() {
    return {
      isLoading: true // Iniciar en true para mostrar el preloader al cargar la página
    };
  },
  methods: {
    handleStartLoading() {
      this.isLoading = true; // Activa el preloader cuando se selecciona un ítem del navbar
      setTimeout(() => {
        this.isLoading = false; // Oculta el preloader después de 2 segundos (simulando carga)
      }, 2000); // Simulando tiempo de carga
    }
  },
  created() {
    // Simulamos una carga inicial, se oculta el preloader después de 2 segundos
    setTimeout(() => {
      this.isLoading = false;
    }, 2000);
  }
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}

/* Asegúrate de que el preloader no cubra el modal */
.pre-loader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.7);
  z-index: 999; /* Está debajo del modal */
}
</style>
