import { createRouter, createWebHistory } from 'vue-router';

const routes = [  
  {
    path: '/',
    name: 'Inicio',
    component: () => import('../views/Inicio.vue') // Importación asíncrona
  },
  {
    path: '/productos',
    name: 'Productos',
    component: () => import('../views/Productos.vue') // Importación asíncrona
  },
  {
    path: '/servicios',
    name: 'Servicios',
    component: () => import('../views/Servicios.vue') // Importación asíncrona
  },
  {
    path: '/nosotros',
    name: 'Nosotros',
    component: () => import('../views/Nosotros.vue') // Importación asíncrona
  },
  {
    path: '/contactenos',
    name: 'Contactenos',
    component: () => import('../views/Contactenos.vue') // Importación asíncrona
  },
  {
    path: '/pqr',
    name: 'PQR',
    component: () => import('../views/PQR.vue') // Importación asíncrona
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

export default router;
