<template>
  <nav class="navbar">
    <div class="navbar-container">
      <!-- Logo en la parte izquierda -->
      <Logo />

      <!-- Botón hamburguesa (solo visible en pantallas pequeñas) -->
      <div class="navbar-toggle" @click="toggleMenu" v-if="isSmallScreen">
        <span class="navbar-toggle-icon" :class="{ 'active': menuActive }"></span>
        <span class="navbar-toggle-icon" :class="{ 'active': menuActive }"></span>
        <span class="navbar-toggle-icon" :class="{ 'active': menuActive }"></span>
      </div>
      
      <!-- Navbar normal (visible en pantallas grandes) -->
      <ul class="navbar-list" v-if="!isSmallScreen || menuActive">
        <li><router-link to="/" active-class="active" @click="onNavClick">Inicio</router-link></li>
        <li><router-link to="/productos" active-class="active" @click="onNavClick">Productos</router-link></li>
        <li><router-link to="/servicios" active-class="active" @click="onNavClick">Servicios</router-link></li>
        <li><router-link to="/nosotros" active-class="active" @click="onNavClick">Nosotros</router-link></li>
        <li><router-link to="/contactenos" active-class="active" @click="onNavClick">Contáctenos</router-link></li>
        <li><router-link to="/pqr" active-class="active" @click="onNavClick">PQR</router-link></li>
      </ul>
    </div>
    
    <!-- Ventana flotante (Modal) para los elementos del menú (solo visible en pantallas pequeñas) -->
    <div v-if="menuActive && isSmallScreen" class="navbar-modal" @click.self="toggleMenu">
      <div class="navbar-modal-content">
        <button class="close-btn" @click="toggleMenu">X</button> <!-- Botón de cerrar -->
        <ul class="navbar-modal-list">
          <li>
            <router-link to="/" active-class="active" @click="onNavClickAndCloseModal" :class="{ 'active': isActive('/') }">Inicio</router-link>
          </li>
          <li>
            <router-link to="/productos" active-class="active" @click="onNavClickAndCloseModal" :class="{ 'active': isActive('/productos') }">Productos</router-link>
          </li>
          <li>
            <router-link to="/servicios" active-class="active" @click="onNavClickAndCloseModal" :class="{ 'active': isActive('/servicios') }">Servicios</router-link>
          </li>
          <li>
            <router-link to="/nosotros" active-class="active" @click="onNavClickAndCloseModal" :class="{ 'active': isActive('/nosotros') }">Nosotros</router-link>
          </li>
          <li>
            <router-link to="/contactenos" active-class="active" @click="onNavClickAndCloseModal" :class="{ 'active': isActive('/contactenos') }">Contáctenos</router-link>
          </li>
          <li>
            <router-link to="/pqr" active-class="active" @click="onNavClickAndCloseModal" :class="{ 'active': isActive('/pqr') }">PQR</router-link>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>

<script>
import Logo from './Logo.vue';

export default {
  name: 'Navbar',
  components: {
    Logo
  },
  data() {
    return {
      menuActive: false,
      currentRoute: this.$route.path,
      windowWidth: window.innerWidth
    };
  },
  computed: {
    isSmallScreen() {
      return this.windowWidth < 768;
    }
  },
  methods: {
    toggleMenu() {
      this.menuActive = !this.menuActive;
    },
    isActive(route) {
      return this.currentRoute === route;
    },
    handleResize() {
      this.windowWidth = window.innerWidth;
      if (!this.isSmallScreen) {
        this.menuActive = false;
      }
    },
    // Método para manejar el clic en los items del navbar y cerrar el modal
    onNavClick() {
      this.$emit('start-loading'); // Emite el evento para indicar que debe comenzar el loading
    },
    // Método que emite un evento para empezar la carga, cierra el modal y navega
    onNavClickAndCloseModal() {
      this.$emit('start-loading'); // Activamos el preloader
      this.menuActive = false; // Cerramos el modal
    }
  },
  watch: {
    '$route'(to) {
      this.currentRoute = to.path;
    }
  },
  mounted() {
    window.addEventListener('resize', this.handleResize);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
  }
};
</script>

<style scoped>
.navbar {
  padding: 10px 20px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: white;
  z-index: 1000; /* Asegúrate de que esté por encima de otros elementos */
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); /* Sombra en la parte inferior */
}

.navbar-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.navbar-toggle {
  display: flex;
  cursor: pointer;
  flex-direction: column;
  justify-content: space-between;
  height: 24px;
  width: 30px;
}

.navbar-toggle-icon {
  background-color: #333;
  height: 4px;
  width: 100%;
  border-radius: 2px;
  transition: transform 0.3s ease, opacity 0.3s ease;
}

.navbar-toggle-icon.active:nth-child(1) {
  transform: rotate(45deg) translate(5px, 5px);
}

.navbar-toggle-icon.active:nth-child(2) {
  opacity: 0;
}

.navbar-toggle-icon.active:nth-child(3) {
  transform: rotate(-45deg) translate(5px, -5px);
}

/* Estilos del modal (ventana flotante) */
.navbar-modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5); /* Fondo semi-transparente */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999; /* Asegura que el modal esté encima del navbar */
}

.navbar-modal-content {
  position: relative;
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  width: 80%;
  max-width: 400px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1); /* Sombra suave */
}

.close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 20px;
  background: none;
  border: none;
  cursor: pointer;
  color: #333;
  font-weight: bold;
}

/* Estilos de los enlaces dentro del modal */
.navbar-modal-list {
  list-style: none;
  padding: 0;
}

.navbar-modal-list li {
  margin: 10px 0;
  text-align: center;
}

.navbar-modal-list li a {
  color: #333;
  text-decoration: none;
  font-weight: bold;
  padding: 10px;
  display: block;
  transition: color 0.3s ease;
}

.navbar-modal-list li a:hover,
.navbar-modal-list li a.active {
  color: green;
}

/* Navbar para pantallas grandes */
.navbar-list {
  list-style: none;
  display: flex;
  justify-content: center; /* Alineamos los items hacia la derecha */
  width: 100%;
  padding-left: 0; /* Quitamos el padding de la lista */
  margin-right: 400px;
}

.navbar-list li {
  margin: 0 12px; /* Ajustamos la separación entre los items */
}

.navbar-list li a {
  color: #333;
  text-decoration: none;
  font-weight: bold;
  padding-bottom: 5px;
  position: relative;
}

/* Línea debajo de cada item solo cuando está activo */
.navbar-list li a::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: transparent; /* La línea está invisible por defecto */
}

.navbar-list li a.active::after {
  background-color: green; /* Solo cuando el item es activo, se muestra la línea verde */
}

.navbar-list li a.active {
  color: green; /* Cambia el color cuando el item está activo */
}

/* Media query para pantallas pequeñas */
@media (max-width: 768px) {
  .navbar-list {
    display: none; /* Escondemos la lista en pantallas pequeñas */
  }
  
  .navbar-toggle {
    display: flex; /* Mostramos el botón de menú en pantallas pequeñas */
  }
}
</style>
