<template>
    <div class="logo">
      <img src="@/assets/cfim.jpg" alt="Logo de la Empresa" class="logo-img" />
    </div>
  </template>
  
  <script>
  export default {
    name: 'Logo'
  };
  </script>
  
  <style scoped>
  .logo {
    display: flex;
    align-items: center;
    justify-content: flex-start; /* Mantener el logo alineado al principio */
    margin-left:30%; /* Mover el logo hacia la derecha agregando un margen */
  }
  
  .logo-img {
    width: 180px; /* Tamaño aumentado del logo */
    height: auto;
    object-fit: contain;
  }
  </style>
  